import React, { useState, useEffect } from 'react'
import API from '../../backend/API'
import './dependencies.scss'

const Dependencies = () => {

    const [dependencies, setDependencies] = useState([])

    useEffect(() => {
        API.getDependencies().then(setDependencies)
    }, [])

    return (
        <section className="section-dependencies pb-5">
            <div className="container">
                <h1>Développé avec</h1>
                <div className="row mt-5">
                    {dependencies.map(d => (
                        <div className="col-md-4 d-flex align-items-center justify-content-center">
                            <img height="50" src={API.getAsset(d.logo.url)} alt={d.name}></img>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Dependencies